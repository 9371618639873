import { GatsbyImage, getImage, IGatsbyImageData } from 'gatsby-plugin-image';
import sanitizeHtml from 'sanitize-html';

// Components
import Button from 'ui-kit/button/button';

// Props
import { DiscountCardInfoProps } from './discount-card-info.props';
// Styles
import './discount-card-info.styles.scss';

const DiscountCardInfo: React.FC<DiscountCardInfoProps> = (props) => {
    const { eyebrow, title, description, secondaryDescription, image, btnLabel, variant, onClick } = props;

    return (
        <div className={`discount-card-description ${variant === 'secondary' ? 'secondary-container-styles' : ''}`}>
            <div className="discount-card-description__container">
                <div className="discount-card-description__copy-area">
                    {eyebrow && <p className="discount-card-description__copy-area__eyebrow">{eyebrow}</p>}
                    <div>
                        <p
                            className="discount-card-description__copy-area__title"
                            dangerouslySetInnerHTML={{
                                __html: sanitizeHtml(title, { allowedTags: ['br'] })
                            }}
                        />
                        <p className="discount-card-description__copy-area__description">{description}</p>
                    </div>
                    {secondaryDescription && (
                        <p className="discount-card-description__copy-area__description-secondary">
                            {secondaryDescription}
                        </p>
                    )}
                    <Button
                        type="button"
                        onClick={onClick}
                        label={btnLabel}
                        className="discount-card-description__copy-area__cta-btn"
                    />
                </div>
                <div className="discount-card-description__image-area">
                    <GatsbyImage image={getImage(image) as IGatsbyImageData} alt={title} />
                </div>
            </div>
        </div>
    );
};

export default DiscountCardInfo;
