import React, { ReactElement } from 'react';

import { useTranslation } from 'gatsby-plugin-react-i18next';
import walgreens from '../../../assets/icons/pharmacies/walgreens.svg';
import kroger from '../../../assets/icons/pharmacies/kroger.svg';
import walmart from '../../../assets/icons/pharmacies/walmart.svg';
import cvs from '../../../assets/icons/pharmacies/cvs.svg';

import './pharmacies.style.scss';

const Pharmacies = (): ReactElement => {
    const { t } = useTranslation();

    return (
        <div className="pharmacies">
            <p>{t('pages.discountCard.pharmacies')}</p>
            <div className="logos">
                <ul>
                    <li>
                        <img src={walgreens} alt={'Walgreens'} />
                    </li>
                    <li>
                        <img src={kroger} alt={'Kroger'} />
                    </li>
                    <li>
                        <img src={walmart} alt={'Walmart'} />
                    </li>
                    <li>
                        <img src={cvs} alt={'Cvs'} />
                    </li>
                </ul>
            </div>
        </div>
    );
};

export default Pharmacies;
