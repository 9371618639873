import { graphql } from 'gatsby';
import { ImageDataLike } from 'gatsby-plugin-image';
import { useTranslation } from 'react-i18next';

import Link from 'ui-kit/link/link';
import PageSection from 'ui-kit/page-section/page-section';

import DiscountCardInfo from 'display-components/birdi-discount/discount-card-info';
import HowItWorksSection from 'display-components/birdi-discount/how-it-works-section';

// Components
import BirdiDiscountCard from 'components/birdi-discount-card';
import LegalText from 'components/discount-card/legal-text/legal-text.component';
import { FeatureCardProps } from 'components/feature-card/feature-card.props';
import FrequentlyAnsweredQuestions from 'components/frequently-answered-questions/frequently-answered-questions.component';
import PageLayout from 'components/layouts/page/page.layout';

import './birdi-discount-card.style.scss';
import Pharmacies from 'components/discount-card/pharmacies/pharmacies.component';

const DiscountCard = ({ data }: { data: GatsbyTypes.DiscountCardDataQuery }) => {
    const { t } = useTranslation();

    const { couponsData, cardBirdiData, featureCardSearchData, featureCardCompletedData, featureCardDiscountData } =
        data;

    const featureCardsItems: FeatureCardProps[] = [
        {
            title: t('pages.discountCard.howItWorks.featureCards.discount.title'),
            description: t('pages.discountCard.howItWorks.featureCards.discount.description'),
            image: featureCardDiscountData as ImageDataLike
        },
        {
            title: t('pages.discountCard.howItWorks.featureCards.search.title'),
            description: t('pages.discountCard.howItWorks.featureCards.search.description'),
            image: featureCardSearchData as ImageDataLike
        },
        {
            title: t('pages.discountCard.howItWorks.featureCards.completed.title'),
            description: t('pages.discountCard.howItWorks.featureCards.completed.description'),
            image: featureCardCompletedData as ImageDataLike
        }
    ];

    return (
        <div className="birdi-discount-card-page">
            <PageLayout metaData={{ nodeTitle: 'Discount Card' }}>
                <PageSection>
                    <div>
                        {/* Page Hero */}
                        <div className="birdi-discount-card-page__hero">
                            <BirdiDiscountCard />
                        </div>
                        {/* End Page Hero */}
                        <div className="birdi-discount-card-page__pharmacies">
                            <Pharmacies />
                        </div>
                        {/* Page sections */}
                        <div className="birdi-discount-card-page__sections">
                            {/* Section - What is the Birdi Discount Card? */}
                            <DiscountCardInfo
                                title={t('pages.discountCard.whatIsBirdiCard.title')}
                                description={t('pages.discountCard.whatIsBirdiCard.description')}
                                secondaryDescription={t('pages.discountCard.whatIsBirdiCard.secondaryDescription')}
                                btnLabel={t('pages.discountCard.whatIsBirdiCard.cta')}
                                image={couponsData as ImageDataLike}
                            />
                            {/* End Section - What is the Birdi Discount Card? */}

                            {/* Section - How the Birdi Discount Card works. */}
                            <HowItWorksSection featureCards={featureCardsItems} />
                            {/* End Section - How the Birdi Discount Card works. */}

                            {/* Section - Pay less on prescriptions with your Birdi Discount Card. */}
                            <DiscountCardInfo
                                variant={'secondary'}
                                eyebrow={t('pages.discountCard.startSaving.eyebrow')}
                                title={t('pages.discountCard.startSaving.title')}
                                description={t('pages.discountCard.startSaving.description')}
                                btnLabel={t('pages.discountCard.whatIsBirdiCard.cta')}
                                image={cardBirdiData as ImageDataLike}
                            />
                            {/* End Section - Pay less on prescriptions with your Birdi Discount Card. */}

                            {/* Section - New FAQ component. */}
                            <FrequentlyAnsweredQuestions />
                            {/* End Section - New FAQ component. */}
                        </div>
                        {/* End page sections */}
                    </div>
                </PageSection>
                {/* Section - Legal Texts */}
                <div className="birdi-discount-card-page__legal-text-container">
                    <PageSection>
                        <LegalText />
                    </PageSection>
                </div>
                {/* End Section - Legal Texts */}
            </PageLayout>
        </div>
    );
};

export default DiscountCard;

export const query = graphql`
    query DiscountCardData($language: String!) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
        notFoundDesktop: file(relativePath: { eq: "assets/images/not-found-background.jpg" }) {
            id
            childImageSharp {
                gatsbyImageData(formats: [AUTO])
            }
        }

        couponsData: file(relativePath: { eq: "assets/images/coupons.png" }) {
            id
            childImageSharp {
                gatsbyImageData(formats: PNG)
            }
        }

        cardBirdiData: file(relativePath: { eq: "assets/images/card-birdi.png" }) {
            id
            childImageSharp {
                gatsbyImageData(formats: PNG)
            }
        }

        featureCardDiscountData: file(relativePath: { eq: "assets/images/feature-card-discount.png" }) {
            id
            childImageSharp {
                gatsbyImageData(formats: PNG)
            }
        }

        featureCardSearchData: file(relativePath: { eq: "assets/images/feature-card-search.png" }) {
            id
            childImageSharp {
                gatsbyImageData(formats: PNG)
            }
        }

        featureCardCompletedData: file(relativePath: { eq: "assets/images/feature-card-completed.png" }) {
            id
            childImageSharp {
                gatsbyImageData(formats: PNG)
            }
        }
    }
`;