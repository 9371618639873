import { useTranslation } from 'gatsby-plugin-react-i18next';
import { ReactElement } from 'react';
// UI Kit & Components
import { Container } from 'react-bootstrap';
import sanitizeHtml from 'sanitize-html';

import AddressBox from 'components/address-box';
import PopularSearches from 'components/popular-searches';
import PrescriptionSearch from 'components/prescription-search';

import './index.styles.scss';

const BirdiDiscountCard = (): ReactElement => {
    const { t } = useTranslation();

    return (
        <Container className="discount-card">
            <div className="discount-card__top-area">
                <h1>{t('pages.discountCard.hero.title')}</h1>
                <h2>{t('pages.discountCard.hero.eyebrow')}</h2>
                <p
                    dangerouslySetInnerHTML={{
                        __html: sanitizeHtml(t('pages.discountCard.hero.description'), {
                            allowedTags: ['strong', 'br']
                        })
                    }}
                />
            </div>
            <div className="discount-card__bottom-area">
                <div className="discount-card__bottom-area__container">
                    <p className="discount-card__bottom-area__eyebrow-text">
                        {t('pages.discountCard.hero.searchCopy')}
                    </p>
                    <div className="discount-card__search-container">
                        <AddressBox />
                        <PrescriptionSearch />
                    </div>
                </div>
                <PopularSearches items={['Amoxicilin', 'Lexapro', 'Atrovastatin', 'Lisinopril']} />
            </div>
        </Container>
    );
};

export default BirdiDiscountCard;
