import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import PageSectionHeader from 'ui-kit/page-section-header/page-section-header';

import FeatureCard from 'components/feature-card';
import { FeatureCardProps } from 'components/feature-card/feature-card.props';

import './how-it-works-section.styles.scss';

interface HowItWorksSectionProps {
    featureCards: FeatureCardProps[];
}

const HowItWorksSection = (props: HowItWorksSectionProps): ReactElement => {
    const { featureCards } = props;
    const { t } = useTranslation();

    return (
        <div className="section-how-it-works">
            <div className="section-how-it-works-header">
                <PageSectionHeader
                    title={t('pages.discountCard.howItWorks.title')}
                    subTitle={t('pages.discountCard.howItWorks.subtitle')}
                />
            </div>
            <div className="section-how-it-works-cards">
                {featureCards.map((cards) => (
                    <FeatureCard {...cards} key={cards.title} />
                ))}
            </div>
        </div>
    );
};

export default HowItWorksSection;
