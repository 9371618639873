import { useTranslation } from 'gatsby-plugin-react-i18next';
import { ReactElement } from 'react';

import { PopularSearchesProps } from './popular-searches.props';
import './popular-searches.styles.scss';

const PopularSearches = ({ items }: PopularSearchesProps): ReactElement => {
    const { t } = useTranslation();

    return (
        <div className="popular-searches__container">
            <p className="popular-searches__title">{t('pages.discountCard.hero.popularSearches')}</p>
            <ul className="popular-searches__list">
                {items.map((item) => (
                    <li key={item} className="popular-searches__list__list-item">
                        {item}
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default PopularSearches;
