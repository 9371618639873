import React, { ReactElement } from 'react';

import { useTranslation } from 'gatsby-plugin-react-i18next';

import './legal-text.style.scss';

const LegalText = (): ReactElement => {
    const { t } = useTranslation();

    return (
        <div className="legal-text">
            <p
                dangerouslySetInnerHTML={{
                    __html: t('pages.discountCard.legalText')
                }}
            ></p>
        </div>
    );
};

export default LegalText;
