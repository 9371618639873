import { ReactElement, useEffect, useState } from 'react';

// UI Kit & Components
import Button from 'ui-kit/button/button';
import PillSearchIcon from 'ui-kit/icons/pill-search-icon/pill-search-icon';
import TextSetValue from 'ui-kit/text/textSetValue';

import './prescription-search.styles.scss';

const mockDropdownData = ['Amoxi-Drop', 'Amoxapine', 'Amoxicilin', 'Amoxi-Tabs', 'Amondys 45'];

const PrescriptionSearch = (): ReactElement => {
    const [search, setSearch] = useState<string>('');
    const [dropdownIndex, setDropdownIndex] = useState<number>(0);
    const [selectedDrug, setSelectedDrug] = useState<string>('');
    const [menuActive, setMenuActive] = useState<boolean>(false);

    const onChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearch(e.target.value);
    };

    const onKeyDownHandler = (e: React.KeyboardEvent<HTMLInputElement>) => {
        setMenuActive(true);

        if (e.key === 'ArrowDown') {
            setDropdownIndex(dropdownIndex < 4 /* OR druglist length */ ? dropdownIndex + 1 : dropdownIndex);
        }

        if (e.key === 'ArrowUp') {
            setDropdownIndex(dropdownIndex !== 0 ? dropdownIndex - 1 : dropdownIndex);
        }

        if (e.key === 'Enter') {
            setSelectedDrug(mockDropdownData[dropdownIndex]);
            setMenuActive(false);
        }

        if (e.key === 'Escape') {
            setMenuActive(false);
        }
    };

    // this one should do the api search // trigger search button action
    const prescriptionClickHandler = (drug: string) => {
        setSelectedDrug(drug);
        setMenuActive(false);
    };

    useEffect(() => {
        if (selectedDrug) {
            setDropdownIndex(-1);
        }

        setSearch(selectedDrug);
        setMenuActive(false);
    }, [selectedDrug]);

    useEffect(() => {
        if (search.length === 0) {
            setMenuActive(false);
        }
    }, [search]);

    return (
        <div className="search-box">
            <TextSetValue
                className="search-box__search-input"
                name={'drugName'}
                label={'Prescripton name'}
                type="text"
                onChange={onChangeHandler}
                onKeyDown={onKeyDownHandler}
                value={search}
            />
            {menuActive && (
                <ul className={'drug-list'} role={'listbox'}>
                    {mockDropdownData.map(
                        (drug: string, i: number) =>
                            drug.startsWith(search) && (
                                <li
                                    role={'option'}
                                    aria-selected={dropdownIndex === i ? 'true' : 'false'}
                                    className={`drug ${dropdownIndex === i ? 'active' : ''}`}
                                    key={i}
                                >
                                    <PillSearchIcon />
                                    <Button
                                        variant="text"
                                        label={drug.replace(search, search ? `<strong>${search}</strong>` : '')}
                                        type="button"
                                        className="my-2 mx-md-2 d-block sm-full d-sm-inline text-dark"
                                        onClick={() => prescriptionClickHandler(drug)}
                                        dangerouslySetLabel
                                    />
                                </li>
                            )
                    )}
                </ul>
            )}

            <Button className="search-box__search-btn" type="button" label="Search" searchIcon />
        </div>
    );
};

export default PrescriptionSearch;
