import { ReactElement } from 'react';

// UI Kit & Components
import Button from 'ui-kit/button/button';
import LocationIcon from 'ui-kit/icons/location-icon/location-icon';

import './address-box.styles.scss';

const AddressBox = (): ReactElement => {
    return (
        <div className="address-box">
            <div>
                <LocationIcon />
                <p className="address-box__address">Los Angeles, CA 90049</p>
            </div>
            <Button className="address-box__update-btn" type="button" variant="text" label="Update" />
        </div>
    );
};

export default AddressBox;
