import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { FAQProps, FAQQuestionProps } from './frequently-answered-questions.props';

import './frequently-answered-questions.style.scss';
import '../../ui-kit/utils/smoke/smoke.style.scss';
import FAQIcon from 'ui-kit/icons/frequently-answered-questions/faq-icon';
import ChevronIcon from 'ui-kit/icons/chevron-icon/chevron-icon';

const frequentylAnsweredQuestionsMock = [
    {
        question: 'What exactly is Birdi Discount Card?',
        answer: 'Birdi Discount Card is a prescription savings card that offers instant savings at the register on both brand name and generic prescription medications. Use the card to save on prescriptions for you and everyone in your flock including pets.'
    },
    {
        question: 'Are the discounts always accurate?',
        answer: 'Birdi Discount Card is a prescription savings card that offers instant savings at the register on both brand name and generic prescription medications. Use the card to save on prescriptions for you and everyone in your flock including pets.'
    },
    {
        question: 'How do I get my discount?',
        answer: 'Birdi Discount Card is a prescription savings card that offers instant savings at the register on both brand name and generic prescription medications. Use the card to save on prescriptions for you and everyone in your flock including pets.'
    },
    {
        question: `A pharmacist didn't accept my card, what do I do?`,
        answer: 'Birdi Discount Card is a prescription savings card that offers instant savings at the register on both brand name and generic prescription medications. Use the card to save on prescriptions for you and everyone in your flock including pets.'
    }
];

const QuestionAnswerPair = ({ question, answer, index }: FAQQuestionProps): ReactElement => {
    return (
        <details className="question-wrapper" data-ga-location={`FAQs ${index + 1}`}>
            <summary>
                <div className="question-query">
                    <FAQIcon />
                    <span className="query-text">{question}</span>
                    <ChevronIcon direction="down" />
                </div>
            </summary>
            <div className="question-answer">{answer}</div>
        </details>
    );
};

const FrequentlyAnsweredQuestions = ({ background, allQuestions, sectionIndex }: FAQProps): ReactElement => {
    const { t } = useTranslation();

    return (
        <div className="frequently-answered-questions-container">
            <div className="title-container">
                <div className="title-container__section">
                    <h3>{t('pages.discountCard.faq.subTitle')}</h3>
                    <h2>{t('pages.discountCard.faq.title')}</h2>
                </div>

                <span>{t('pages.discountCard.faq.spanText')}</span>
            </div>
            <div className="frequently-answered-questions-section">
                {frequentylAnsweredQuestionsMock.map(({ question, answer }, index) => {
                    return <QuestionAnswerPair index={index} key={index} question={question} answer={answer} />;
                })}
            </div>
        </div>
    );
};

export default FrequentlyAnsweredQuestions;
