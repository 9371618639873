import React, { CSSProperties, ReactElement } from 'react';

const PillSearchIcon = ({ className, style }: { className?: string; style?: CSSProperties }): ReactElement => {
    return (
        <div className={`pill-search-icon ${className ?? ''}`} style={style}>
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g id="Bounding box">
                    <path
                        id="Vector"
                        d="M7.26338 2.55001C8.00955 1.7714 9.02157 1.33398 10.0768 1.33398C11.132 1.33398 12.1441 1.7714 12.8902 2.55001C13.6364 3.32862 14.0556 4.38463 14.0556 5.48575C14.0556 6.58687 13.6364 7.64289 12.8902 8.42149L8.07003 13.4512C7.32386 14.2298 6.31185 14.6673 5.25661 14.6673C4.20137 14.6673 3.18935 14.2298 2.44319 13.4512C1.69702 12.6727 1.27783 11.6166 1.27783 10.5155C1.27783 9.4144 1.69702 8.35839 2.44319 7.57978L7.26338 2.55001Z"
                        stroke="#1A497F"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                    <path
                        id="Vector_2"
                        d="M5.72754 5.35742L10.2452 10.0715"
                        stroke="#1A497F"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                </g>
            </svg>
        </div>
    );
};

export default PillSearchIcon;
